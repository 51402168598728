
































































import Vue from 'vue'
import { mapState } from 'vuex'
import { Task } from '@/interfaces'

export default Vue.extend({
  data() {
    return { startDate: '', endDate: '', limitDate: '', taskClass: '' }
  },
  computed: {
    ...mapState(['activeTask']),
  },
  watch: {
    activeTask(newVal: Task) {
      if (newVal != null) {
        this.updateTaskDates(newVal)
      }
    },
  },
  mounted() {
    this.updateTaskDates(this.activeTask)
  },
  methods: {
    getDate(date?: string): string {
      if (date != null) {
        const realDate = new Date(date)
        const day = realDate.getDate().toString()
        const month = realDate.getMonth() + 1
        const year = realDate.getFullYear().toString()

        return day.padStart(2, '0') + '/' + month.toString().padStart(2, '0') + '/' + year
      } else {
        return '?'
      }
    },
    updateTaskDates(task: Task) {
      this.startDate = this.getDate(task.startDate)
      this.endDate = this.getDate(task.endDate)
      this.limitDate = this.getDate(task.limitDate)
    },
    getStatus(status: string) {
      this.taskClass = `task-${status.toLowerCase()}`
      switch (status) {
        case 'TODO':
          return 'À faire'
        case 'DRAFT':
          return 'Brouillon'
        case 'PLANNED':
          return 'Planifiée'
        case 'CLOSED':
          return 'Fermée'
      }
    },
  },
})
