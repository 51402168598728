




























import Vue from 'vue'
import GlobalToast from '@/components/GlobalToast.vue'
import Sidebar from '@/components/Sidebar.vue'
import ImminentTasks from '@/components/ImminentTasks.vue'
import { mapState, mapActions } from 'vuex'

export default Vue.extend({
  components: { GlobalToast, Sidebar, ImminentTasks },
  computed: {
    ...mapState(['taskWorkers', 'user', 'taskQualities', 'taskTypes']),
  },
  watch: {
    user(newVal) {
      const currentRoute = this.$router.currentRoute
      if (newVal != null && currentRoute.name !== 'dashboard') {
        // Redirecting to dashboard
        this.$router.push({ name: 'dashboard' })
      }
    },
  },
  mounted() {
    // In case of a hard reload, the store might have to be refreshed
    if (this.$cookies.get('authToken') && this.user == null) {
      this.loadAppData().catch((error) => {
        if (error.response?.status === 401) {
          this.logoutUser()
        }
      })
    }
  },
  methods: {
    ...mapActions(['logout', 'loadAppData']),
    logoutUser() {
      // Closing sidebar
      const app = document.getElementById('app')
      app?.classList.remove('sidebar-open-wide', 'sidebar-open')

      // Destroying user inside store, removing token and redirecting to home
      this.logout()
      this.$cookies.remove('authToken')

      const currentRoute = this.$router.currentRoute

      if (currentRoute.name != 'home') {
        this.$router.push({ name: 'home' })
      }
    },
    openImminentTasks() {
      this.$root.$emit('openImminentTasks')
    },
  },
})
