




import Vue from 'vue'
import { mapState, mapActions } from 'vuex'

export default Vue.extend({
  data() {
    return {
      toast: {
        type: 'info',
        title: '',
        content: '',
      },
    }
  },
  computed: {
    ...mapState(['globalToast']),
  },
  watch: {
    globalToast(newVal, oldVal) {
      if (newVal.content !== null && oldVal.content != newVal.content) {
        // Display toast
        this.$bvToast.toast(this.globalToast.content, {
          title: this.globalToast.title,
          variant: this.globalToast.type,
        })

        // Reset toast (to avoid repetition)
        this.setGlobalToast({
          type: 'info',
          title: '',
          content: null,
        })
      }
    },
  },
  methods: {
    ...mapActions(['setGlobalToast']),
  },
})
