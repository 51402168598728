








































import Vue from 'vue'
import axios from '@/axios-instance'
import { Task } from '@/interfaces'
import { mapActions, mapState } from 'vuex'

export default Vue.extend({
  data() {
    return {
      busy: false,
      imminentTasks: [],
      fields: [
        { key: 'id', label: 'ID' },
        { key: 'label', label: 'Nom' },
        { key: 'limitDate', label: 'Date Max.' },
        { key: 'status', label: 'Statut' },
        { key: 'worker.fullName', label: 'Prestataire' },
      ],
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    user(newVal) {
      // Modal shall be opened only if a user is logged in
      if (newVal != null) {
        this.refreshImminentTasks()
      }
    },
  },
  mounted() {
    // Listen for event
    this.$root.$on('openImminentTasks', () => {
      this.refreshImminentTasks()
    })
  },
  methods: {
    ...mapActions(['setActiveTask']),
    refreshImminentTasks() {
      this.imminentTasks = []
      this.busy = true
      this.$bvModal.show('imminentTasksModal')

      // Load imminent tasks
      axios
        .get('/imminenttasks')
        .then((response) => {
          this.imminentTasks = response.data
        })
        .finally(() => {
          this.busy = false
        })
    },
    getStatus(status: string): string {
      switch (status) {
        case 'DRAFT':
          return 'Brouillon'

        case 'CLOSED':
          return 'Fermée'

        case 'TODO':
          return 'À faire'

        case 'PLANNED':
          return 'Planifiée'

        default:
          return 'Inconnu'
      }
    },
    click(task: Task) {
      this.setActiveTask(task)
      this.$bvModal.hide('imminentTasksModal')
    },
    formatDate(limitDate: string): string {
      const date = new Date(limitDate)
      const formattedLimitDate = new Intl.DateTimeFormat('fr').format(date)

      return formattedLimitDate
    },
    cutLabel(label: string): string {
      if (label.length > 50) {
        return `${label.substring(0, 50)}...`
      } else {
        return label
      }
    },
  },
})
