


































































import Vue from 'vue'
import axios from '@/axios-instance'
import { BModal } from 'bootstrap-vue'
import { mapActions } from 'vuex'

export default Vue.extend({
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      showPassword: false,
      rememberMe: false,
    }
  },
  mounted() {
    if (process.env.NODE_ENV == 'development') {
      this.form = {
        email: 'admin@geotop.test',
        password: 'password',
      }
    }
  },
  methods: {
    ...mapActions(['setGlobalToast', 'loadAppData']),
    login() {
      let formData = new FormData()
      formData.append('email', this.form.email)
      formData.append('password', this.form.password)

      axios
        .post('/api_login', formData)
        .then((response) => {
          // Set token and redirect to dashboard
          if (this.$cookies.get('authToken')) {
            this.$cookies.remove('authToken')
          }

          let cookieTime = '0'

          if (this.rememberMe) {
            cookieTime = '30d'
          }

          // Setting session cookie
          this.$cookies.set('authToken', response.data.token, cookieTime)

          // Getting user infos
          this.loadAppData()

          // Redirecting
          this.$router.push({ name: 'dashboard' })
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401 || error.response.status === 403) {
              // Setting global toast
              this.setGlobalToast({
                type: 'danger',
                title: 'Erreur !',
                content: 'Email et/ou mot de passe incorrects.',
              })
            }
          }
        })
    },
    onReset() {
      this.form = {
        email: '',
        password: '',
      }
    },
    togglePasswordFieldDisplay(): void {
      this.showPassword = !this.showPassword
    },
    sendRecoveryEmail() {
      const formData = new FormData()
      formData.append('email', this.form.email)

      axios
        .post('/passwordresetrequest', formData)
        .then((response) => {
          if (response.success) {
            this.setGlobalToast({
              type: 'success',
              title: 'Merci !',
              content: 'Un e-mail contenant un lien de réinitialisation vous a été envoyé.',
            })
          } else {
            this.setGlobalToast({
              type: 'danger',
              title: 'Erreur !',
              content: 'Utilisateur introuvable. Merci de vérifier votre adresse e-mail.',
            })
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            this.setGlobalToast({
              type: 'danger',
              title: 'Erreur !',
              content:
                "Une erreur est survenue lors de l'envoi de l'e-mail de réinitialisation. Veuillez nous excuser pour ce désagrément.",
            })
          }
        })
        .finally(() => {
          ;(this.$refs.forgotPasswordModal as BModal).hide()
        })
    },
  },
})
