










































































































































import Vue from 'vue'
import AddTaskModal from '@/components/TaskForm.vue'
import TaskDetails from '@/components/TaskDetails.vue'
import { mapState, mapGetters, mapActions } from 'vuex'

export default Vue.extend({
  components: { AddTaskModal, TaskDetails },
  data() {
    return {
      sidebarOpen: false,
      readonlyModal: false,
      editMode: false,
    }
  },
  computed: {
    ...mapState(['activeTask']),
    ...mapGetters(['isAdmin', 'isRequester']),
    adminUrl() {
      return process.env.VUE_APP_ADMIN_URL
    },
  },
  watch: {
    activeTask(newVal): void {
      const app = document.getElementById('app')
      if (newVal != null) {
        app?.classList.add('sidebar-open', 'sidebar-open-wide')
        if (this.sidebarOpen == false) {
          this.sidebarOpen = true
        }
      } else {
        app?.classList.remove('sidebar-open-wide')
      }
    },
  },
  mounted() {
    const app = document.getElementById('app')
    app?.classList.remove('sidebar-open', 'sidebar-open-wide')
  },
  methods: {
    ...mapActions(['setActiveTask']),
    addTask(): void {
      this.setActiveTask(null)
      this.openTaskModal(false, false)
    },
    showTaskDetails(): void {
      this.editMode = false
      this.openTaskModal(true, false)
    },
    editTask() {
      this.openTaskModal(false, true)
    },
    copyTask() {
      this.openTaskModal(false, false)
    },
    openTaskModal(readOnly: boolean, editMode: boolean): void {
      this.readonlyModal = readOnly
      this.editMode = editMode
      this.$bvModal.show('addTaskModal')
    },
    toggleSidebar(): void {
      this.sidebarOpen = !this.sidebarOpen
      const app = document.getElementById('app')

      if (this.sidebarOpen == false) {
        this.setActiveTask(null)

        app?.classList.remove('sidebar-open', 'sidebar-open-wide')
      } else {
        app?.classList.add('sidebar-open')
      }

      this.$root.$emit('toggleSidebar')
    },
  },
})
