import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    component: Home,
    name: 'home',
    path: '/',
  },
  {
    // route level code-splitting
    // this generates a separate chunk (dashboard.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    name: 'dashboard',
    path: '/dashboard',
  },
  {
    component: () => import(/* webpackChunkName: "recovery" */ '../views/Recovery.vue'),
    name: 'recover',
    path: '/recover/:token',
    props: true,
  },
  {
    component: () => import(/* webpackChunkName: "logout" */ '../views/Logout.vue'),
    name: 'logout',
    path: '/logout',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
