




import Vue from 'vue'
import LoginForm from '@/components/LoginForm.vue'

export default Vue.extend({
  name: 'Home',
  components: {
    LoginForm,
  },
})
