import Vue from 'vue'
import Vuex from 'vuex'
import axios from '@/axios-instance'
import { BVSelect, TaskType, User } from '@/interfaces'

Vue.use(Vuex)

let busy = false

export default new Vuex.Store({
  state: {
    activeTask: null,
    globalToast: {
      type: 'info',
      title: '',
      content: '',
    },
    user: null as User | null,
    taskWorkers: [],
    taskRequesters: [],
    taskQualities: [],
    taskTypes: [],
    filters: {
      workers: [] as number[],
      types: [] as number[],
    },
    taskslist: {},
    progress: [] as number[],
    calendarWorker: null as number | null,
  },
  mutations: {
    setGlobalToast(state, toast) {
      state.globalToast = toast
    },
    setUser(state, userInfos) {
      state.user = userInfos
    },
    setActiveTask(state, task) {
      state.activeTask = task
    },
    setTaskWorkers(state, workers) {
      state.taskWorkers = workers
    },
    setTaskRequesters(state, requesters) {
      state.taskRequesters = requesters
    },
    setTaskQualities(state, qualities) {
      state.taskQualities = qualities
    },
    setTaskTypes(state, taskTypes) {
      state.taskTypes = taskTypes
    },
    setFilters(state, args) {
      state.filters = args
    },
    setTasksList(state, tasks) {
      state.taskslist = tasks
    },
    setCalendarWorker(state, workerId) {
      state.calendarWorker = workerId
    },
    addProgress(state) {
      state.progress.push(1)
    },
    removeProgress(state) {
      state.progress.splice(0, 1)
    },
    logout(state) {
      state.activeTask = null
      state.user = null
      state.taskQualities = []
      state.taskWorkers = []
      state.taskTypes = []
      state.taskRequesters = []
    },
  },
  actions: {
    setUser: ({ commit }) => commit('setUser'),
    setActiveTask: ({ commit }, task) => commit('setActiveTask', task),
    setTaskWorkers: ({ commit }) => commit('setTaskWorkers'),
    setTaskRequesters: ({ commit }) => commit('setTaskRequesters'),
    setTaskQualities: ({ commit }) => commit('setTaskQualities'),
    setTaskTypes: ({ commit }) => commit('setTaskTypes'),
    setCalendarWorker: ({ commit }, workerId) => commit('setCalendarWorker', workerId),
    addProgress: ({ commit }) => commit('addProgress'),
    removeProgress: ({ commit }) => commit('removeProgress'),
    logout: ({ commit }) => commit('logout'),
    setGlobalToast: ({ commit }, toast) => commit('setGlobalToast', toast),
    loadAppData({ commit }) {
      // Get user
      if (busy === false) {
        busy = true
        return axios
          .get('/me')
          .then((response) => {
            commit('setUser', response.data)

            // Getting app data (for filters and form)
            axios.get('/appdata').then((response) => {
              if (response && response.success === true) {
                // Format types data
                const types: BVSelect[] = []

                response.data.types.forEach((type: TaskType) => {
                  const newType: BVSelect = {
                    text: type.label,
                    value: type.id.toString(),
                  }

                  types.push(newType)
                })

                // Populate store
                commit('setTaskTypes', types)
                commit('setTaskQualities', response.data.qualities)
                commit('setTaskWorkers', response.data.workers)
                commit('setTaskRequesters', response.data.requesters)
              }
            })
          })
          .finally(() => {
            busy = false
          })
      }
    },
    setFilters: ({ commit }, filters) => commit('setFilters', filters),
    setTasksList: ({ commit }, tasks) => commit('setTasksList', tasks),
  },
  getters: {
    getGlobalToast(state) {
      return state.globalToast
    },
    getUser(state) {
      return state.user
    },
    getActiveTask(state) {
      return state.activeTask
    },
    getWorkers(state) {
      return state.taskWorkers
    },
    getQualities(state) {
      return state.taskQualities
    },
    getTaskTypes(state) {
      return state.taskTypes
    },
    getFilters(state) {
      return state.filters
    },
    getTasksList(state) {
      return state.taskslist
    },
    getProgress(state) {
      return state.progress
    },
    isAdmin(state) {
      return state.user?.roles?.includes('ROLE_ADMIN') ? true : false
    },
    isWorker(state) {
      return state.user?.roles.includes('ROLE_WORKER') ? true : false
    },
    isRequester(state) {
      return state.user?.roles.includes('ROLE_REQUESTER') ? true : false
    },
    isPlanner(state) {
      return state.user?.roles.includes('ROLE_PLANNER') ? true : false
    },
  },
  modules: {},
})
