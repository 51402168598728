















































































































































































import Vue from 'vue'
import axios from '@/axios-instance'
import myAxios from 'axios'
import { mapState, mapGetters, mapActions } from 'vuex'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import { BVSelect } from '@/interfaces'

interface InitialFormState {
  id?: number
  address: string
  addressLine2: string
  description: string
  duration: string
  label: string
  limitDate: string | null
  postalCode: string
  town: string
  type: number | null
  startDate?: string | null
  endDate?: string | null
  quality?: number | null
  status: string | null
  lat: number | null
  long: number | null
}

interface TaskDateObject {
  year: number
  month: number
  day: number
  hours: number
  minutes: number
}

interface NuminatimData {
  lat: string
  lon: string
  address: {
    postcode: string
  }
}

const initialFormState: InitialFormState = {
  address: '',
  addressLine2: '',
  description: '',
  duration: '',
  label: '',
  limitDate: null,
  postalCode: '',
  town: '',
  type: null,
  startDate: null,
  endDate: null,
  quality: null,
  status: 'TODO',
  lat: null,
  long: null,
}

export default Vue.extend({
  components: { VueTimepicker },
  props: {
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      busy: false,
      form: initialFormState,
      taskStart: {
        HH: '08',
        mm: '00',
      },
      taskEnd: {
        HH: '09',
        mm: '00',
      },
      taskState: [
        {
          text: 'Brouillon',
          value: 'DRAFT',
        },
        {
          text: 'À faire',
          value: 'TODO',
        },
        {
          text: 'Planifié',
          value: 'PLANNED',
        },
        {
          text: 'Fermé',
          value: 'CLOSED',
        },
      ] as BVSelect[],
      geoLocWarning: false,
      geoLocError: false,
      geoLocNoResult: false,
      geolocSearchStatus: '',
    }
  },
  computed: {
    ...mapState(['activeTask', 'taskWorkers', 'taskQualities', 'taskTypes']),
    ...mapGetters(['isAdmin', 'isWorker', 'isRequester']),
  },
  watch: {
    activeTask(newVal) {
      if (newVal != null) {
        this.form = {
          ...this.activeTask,
          type: this.activeTask.type ? this.activeTask.type.id : null,
          quality: this.activeTask.quality ? this.activeTask.quality.id : null,
          worker: this.activeTask.worker ? this.activeTask.worker.id : null,
        }

        const taskStart = new Date(Date.parse(this.activeTask.startDate))
        const taskEnd = new Date(Date.parse(this.activeTask.endDate))

        this.taskStart = {
          HH: taskStart.getUTCHours().toString().padStart(2, '0'),
          mm: taskStart.getUTCMinutes().toString().padStart(2, '0'),
        }

        this.taskEnd = {
          HH: taskEnd.getUTCHours().toString().padStart(2, '0'),
          mm: taskEnd.getUTCMinutes().toString().padStart(2, '0'),
        }
      } else {
        this.form = initialFormState
        this.geoLocWarning = false
        this.geoLocError = false
        this.geoLocNoResult = false
        this.taskStart = {
          HH: '08',
          mm: '00',
        }
        this.taskEnd = {
          HH: '09',
          mm: '00',
        }
      }
    },
  },
  mounted() {
    this.$root.$on('bv::modal::show', () => {
      // New task or task copy? Let's reset the form accordingly
      if (!this.editMode && !this.readonly) {
        if (this.activeTask === null) {
          // New task
          this.resetForm()

          // If the current user is a requester, some fields have to be prefilled
          if (this.isRequester) {
            this.form.status = 'DRAFT'
          }
        } else {
          // Task copy
          delete this.form.quality
          delete this.form.id
        }
      }
    })
  },
  methods: {
    ...mapActions(['setGlobalToast', 'setActiveTask']),
    submit(): void {
      // If the GPS coords are unknown, the form silently returns
      if (!this.form.lat || !this.form.long) {
        return
      }

      // Creating form data object, parsing duration as int
      const data = {
        ...this.form,
        duration: parseInt(this.form.duration),
      }

      // Failsafe for the limit date in case the reset button has been pushed
      if (data.limitDate === '') {
        data.limitDate = null
      }

      const method = this.editMode == false ? 'post' : 'patch'
      const url = this.editMode == false ? '/tasks' : `/task/${this.activeTask.id}`

      // If we're editing, we shall take care of the date inputs for start and end dates, and reconstruct those values
      if (this.editMode) {
        if (this.form.startDate) {
          const taskStartDate = new Date(Date.parse(this.form.startDate))
          let taskStart: TaskDateObject = {
            year: taskStartDate.getFullYear(),
            month: taskStartDate.getMonth(),
            day: taskStartDate.getDate(),
            hours: parseInt(this.taskStart.HH),
            minutes: parseInt(this.taskStart.mm),
          }

          if (this.isValidDateObject(taskStart)) {
            const formattedTaskStart = new Date(
              Date.UTC(taskStart.year, taskStart.month, taskStart.day, taskStart.hours, taskStart.minutes)
            ).toISOString()
            data.startDate = formattedTaskStart
          } else {
            delete data.startDate
          }
        }

        if (this.form.endDate) {
          const taskEndDate = new Date(Date.parse(this.form.endDate))
          let taskEnd: TaskDateObject = {
            year: taskEndDate.getFullYear(),
            month: taskEndDate.getMonth(),
            day: taskEndDate.getDate(),
            hours: parseInt(this.taskEnd.HH),
            minutes: parseInt(this.taskEnd.mm),
          }

          if (this.isValidDateObject(taskEnd)) {
            const formattedTaskEnd = new Date(
              Date.UTC(taskEnd.year, taskEnd.month, taskEnd.day, taskEnd.hours, taskEnd.minutes)
            ).toISOString()
            data.endDate = formattedTaskEnd
          } else {
            delete data.endDate
          }
        }

        // If the status is changed for 'TODO' or 'DRAFT', we shall remove the task from the calendar
        if (this.form.status === 'DRAFT' || this.form.status === 'TODO') {
          delete data.startDate
          delete data.endDate
        }
      }

      // If either the startDate or the endDate has been reseted, or if the task is marked
      // as DRAFT or TOTO, it should be removed from the calendar as well
      if (
        this.form.status == 'DRAFT' ||
        this.form.status == 'TODO' ||
        this.form.startDate == '' ||
        this.form.endDate == ''
      ) {
        data.startDate = null
        data.endDate = null
      }

      axios({
        method,
        url,
        data,
      })
        .then((response): void => {
          this.setGlobalToast({
            type: 'success',
            title: 'Succès !',
            content: 'Votre tâche a été enregistrée avec succès.',
          })

          // Set active task to the one that was just created
          this.setActiveTask(response.data)

          // As a failsafe, since we've probably received new data, the calendar should be updated
          this.$root.$emit('updateCalendar')

          // If we're in create/edit mode, the task list should be updated
          if (this.readonly === false) {
            this.$root.$emit('taskUpdated')
          }
        })
        .catch((error): void => {
          if (error.response.status === 400) {
            // Setting global toast
            this.setGlobalToast({
              type: 'danger',
              title: 'Erreur !',
              content:
                "Une erreur est survenue lors de l'enregistrement de votre tâche. Merci de vérifier les données de cette dernière.",
            })
          }

          if (error.response.status === 403) {
            this.setGlobalToast({
              type: 'danger',
              title: 'Erreur !',
              content: 'Vous ne disposez pas des droits suffisants pour réaliser cette opération.',
            })
          }
        })
        .finally(() => {
          this.$bvModal.hide('addTaskModal')
        })
    },
    resetForm(): void {
      this.form = {
        address: '',
        addressLine2: '',
        description: '',
        duration: '',
        label: '',
        limitDate: null,
        postalCode: '',
        town: '',
        type: null,
        startDate: '',
        endDate: '',
        quality: null,
        status: 'TODO',
        lat: null,
        long: null,
      }

      this.taskStart = {
        HH: '08',
        mm: '00',
      }

      this.taskEnd = {
        HH: '09',
        mm: '00',
      }
    },
    isValidDateObject(obj: TaskDateObject): boolean {
      let isValid = true
      Object.values(obj).forEach((value) => {
        if (isNaN(value)) {
          isValid = false
        }
      })

      return isValid
    },
    locate() {
      this.geoLocWarning = false
      this.geoLocError = false
      this.geoLocNoResult = false
      this.form.lat = null
      this.form.long = null
      this.busy = true
      this.geolocSearchStatus = ''

      const address = this.form.address === null ? '' : this.form.address
      const address2 = this.form.addressLine2 === null ? '' : this.form.addressLine2
      let secondRequest = false
      this.geolocSearchStatus = 'Localisation en cours...'

      myAxios
        .get('https://nominatim.openstreetmap.org/search', {
          params: {
            street: `${address} ${address2}`,
            postalcode: this.form.postalCode,
            city: this.form.town,
            format: 'json',
            addressdetails: 1,
          },
        })
        .then((res) => {
          // Checking the response's length to see if we need to make a second request
          if (res.data) {
            const response = res.data
            const nbResults = Object.keys(response).length

            // No results to show
            if (!nbResults || !response[0]?.lat || !response[0]?.lon) {
              if (address2 !== '') {
                this.busy = true
                secondRequest = true
                this.geolocSearchStatus = "Localisation en cours... Recherche sans le complément d'adresse..."
                myAxios
                  .get('https://nominatim.openstreetmap.org/search', {
                    params: {
                      street: `${address}`,
                      postalcode: this.form.postalCode,
                      city: this.form.town,
                      format: 'json',
                      addressdetails: 1,
                    },
                  })
                  .then((response) => {
                    this.handleLocateData(response)
                  })
                  .finally(() => {
                    this.busy = false
                  })
              } else {
                this.geoLocNoResult = true
                this.geolocSearchStatus = ''
              }
            } else {
              this.handleLocateData(response)
            }
          } else {
            this.geoLocError = true
            this.geolocSearchStatus = ''
          }
        })
        .catch(() => {
          this.geolocSearchStatus = ''
        })
        .finally(() => {
          if (secondRequest === false) {
            this.busy = false
          }
        })
    },
    handleLocateData(data: NuminatimData[]) {
      const nbResults = Object.keys(data).length

      if (!nbResults || !data[0]?.lat || !data[0]?.lon) {
        // No results
        this.geoLocNoResult = true
      }

      // Too many results
      if (nbResults > 2) {
        this.geoLocWarning = true
      }

      // For usability purpose, we shall get the first result no matter what.
      if (data[0]?.lat && data[0]?.lon) {
        this.form.long = parseFloat(data[0].lon)
        this.form.lat = parseFloat(data[0].lat)
      }

      // Setting postal code
      if (this.form.postalCode == '' && data[0]?.address.postcode) {
        this.form.postalCode = data[0].address.postcode
      }

      this.geolocSearchStatus = ''
    },
  },
})
