import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PortalVue from 'portal-vue'
import {
  BootstrapVue,
  BIcon,
  BIconLockFill,
  BIconEye,
  BIconEyeSlash,
  BIconGeoAltFill,
  BIconBoundingBoxCircles,
} from 'bootstrap-vue'
import VueCookies from 'vue-cookies'
import axios from './axios-instance'
import NProgress from 'nprogress'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
import { Icon } from 'leaflet'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'nprogress/nprogress.css'
import 'leaflet/dist/leaflet.css'
import '@/scss/main.scss'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.component('BIcon', BIcon)
Vue.component('BIconLockFill', BIconLockFill)
Vue.component('BIconEye', BIconEye)
Vue.component('BIconEyeSlash', BIconEyeSlash)
Vue.component('BIconGeoAltFill', BIconGeoAltFill)
Vue.component('BIconBoundingBoxCircles', BIconBoundingBoxCircles)

Vue.use(PortalVue)
Vue.use(VueCookies)
Vue.component('l-map', LMap)
Vue.component('l-tile-layer', LTileLayer)
Vue.component('l-marker', LMarker)

type D = Icon.Default & {
  _getIconUrl?: string
}

delete (Icon.Default.prototype as D)._getIconUrl

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

const startProgress = (): void => {
  if (store.state.progress.length == 0) {
    NProgress.start()
  }
  store.dispatch('addProgress')
}

const endProgress = (): void => {
  store.dispatch('removeProgress')
  if (store.state.progress.length == 0) {
    NProgress.done()
  }
}

new Vue({
  router,
  store,
  created: function () {
    axios.interceptors.request.use(
      (config) => {
        startProgress()
        return config
      },
      (error) => {
        endProgress()
        return Promise.reject(error)
      }
    )
    axios.interceptors.response.use(
      (res) => {
        endProgress()

        // Injecting response status in object returned to axios
        if (res) {
          return {
            ...res.data,
            status: res.status,
          }
        }
      },
      (error) => {
        endProgress()
        return Promise.reject(error)
      }
    )
  },
  mounted: function () {
    NProgress.done()
  },
  render: (h) => h(App),
}).$mount('#app')
