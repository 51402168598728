import axios from 'axios'
import Vue from 'vue'
import * as dotenv from 'dotenv'
import router from '@/router'

dotenv.config({ path: '../.env' })

const instance = axios.create()

// axios defaults
instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
instance.defaults.baseURL = process.env.VUE_APP_API_URL
instance.defaults.headers.post['Content-Type'] = 'application/json'

// Interceptors to send the JWT with each request, if it exists
instance.interceptors.request.use(
  (config) => {
    const token = Vue.$cookies.get('authToken')
    if (token != null) {
      config.headers.common['Authorization'] = 'Bearer ' + token
    }

    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (
      error.response?.status === 401 &&
      (error.response?.data?.message === 'Expired JWT Token' ||
        error.response?.data?.message === 'Invalid JWT Token' ||
        error.response?.data?.message === 'JWT Token not found')
    ) {
      router.push({ name: 'logout' })
    } else {
      return Promise.reject(error)
    }
  }
)

export default instance
